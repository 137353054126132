import React, { Component } from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'

class BlogPagination extends Component {
  constructor() {
    super()

    this.navigate = this.navigate.bind(this)
  }

  get isTagged() {
    return !!this.props.meta.tag
  }

  get base() {
    return this.isTagged ? `/blog/tags/${this.props.meta.tag.name}` : '/blog'
  }

  get total() {
    return Array.from({ length: this.props.pageCount }).map((_, index) => index + 1)
  }

  get amountOver() {
    const { current, pageCount } = this.props
    return Math.max(current + 2 - pageCount, 0)
  }

  get amountUnder() {
    const { current } = this.props
    return Math.min(current - 3, 0)
  }

  get pages() {
    const { current, pageCount } = this.props
    return this.total.slice(
      Math.max(current - 3 - this.amountOver, 0),
      Math.min(current + 2 - this.amountUnder, pageCount),
    )
  }

  navigate(page) {
    if (page === 1) {
      return this.base
    }
    if (this.isTagged) {
      return `${this.base}/${page}`
    }
    return `${this.base}/page/${page}`
  }

  render() {
    const { current, isFirst, pageCount } = this.props

    return (
      <nav
        className={cx('blog-pagination', {
          'blog-pagination--hide': pageCount <= 1,
        })}
      >
        <ul className="blog-pagination--nav-list">
          {!isFirst && (
            <li className="blog-pagination--list-item blog-pagination--list-item__prev">
              <Link
                rel="prev"
                className="blog-pagination--list-item-link"
                to={current - 1 !== 1 ? `${this.base}/${!this.isTagged ? 'page/' : ''}${current - 1}` : this.base}
              >
                &lt;
                <span className="screen-reader"> Previous Page</span>
              </Link>
            </li>
          )}

          {this.pages.map((page, index) => (
            <li
              key={page}
              id={`blog-pagination-${index + 1}`}
              className="blog-pagination--list-item blog-pagination--list-item__1"
            >
              <Link
                className={cx('blog-pagination--list-item-link', {
                  'blog-pagination--list-item-link__current': current === page,
                })}
                to={this.navigate(page)}
              >
                <span className="screen-reader">Go to page </span>
                {page}
              </Link>
            </li>
          ))}

          {current !== pageCount && (
            <li className="blog-pagination--list-item blog-pagination--list-item__next">
              <Link
                rel="next"
                className="blog-pagination--list-item-link"
                to={`${this.base}/${!this.isTagged ? 'page/' : ''}${current + 1}`}
              >
                <span className="screen-reader">Next Page </span>
                &gt;
              </Link>
            </li>
          )}
        </ul>
      </nav>
    )
  }
}

export default BlogPagination
