import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const ServiceProsResults = props => {
  const {
    content: {
      data,
      metadata: { headline, subheadline },
    },
  } = props

  return (
    <Section className="service-pros-results" innerClassName="service-pros-results__wrapper" isCentered>
      <div className="service-pros-results__titles">
        <h1 className="service-pros-results__headline">{headline}</h1>
        <h2 className="service-pros-results__subheadline">{subheadline}</h2>
      </div>
      <div className="service-pros-results__content">
        {data.map(({ id, markdown }, index) => (
          <ReactMarkdown
            escapeHtml={false}
            source={markdown.content}
            key={id}
            className={classNames('service-pros-results__item', {
              'service-pros-results__item--first': index === 0,
              'service-pros-results__item--second': index === 1,
              'service-pros-results__item--third': index === 2,
            })}
          />
        ))}
      </div>
    </Section>
  )
}

export default ServiceProsResults
