import React from 'react'
import { Section } from '../../../../index'

const TwoPics = props => {
  const {
    content: { images },
  } = props

  return (
    <Section className="two-pics">
      <img key={images[0].id} className="two-pics__image1" src={images[0].file.url} alt={images[0].title} />
      <img key={images[1].id} className="two-pics__image2" src={images[1].file.url} alt={images[1].title} />
    </Section>
  )
}

export default TwoPics
