import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const LibraryResults = ({ content: { markdown } }) => (
  <Section className="library-results" innerClassName="library-results__wrapper" isCentered>
    <section className="library-results__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </section>
  </Section>
)

export default LibraryResults
