import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const LandingIntro = ({ content: { markdown, images } }) => (
  <Section className="landing-intro" innerClassName="landing-intro__inner" withPadding isCentered>
    <section className="landing-intro__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
      {(images || []).map(({ id, sizes, title }) => (
        <img
          key={id}
          className="landing-intro__image"
          src={sizes.src}
          srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
          alt={title}
          sizes="100vw"
        />
      ))}
    </section>
  </Section>
)

export default LandingIntro
