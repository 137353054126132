import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const CareerIntro = ({ content: { markdown, metadata } }) => (
  <Section
    className={classNames('career-intro', metadata ? metadata.className : '')}
    innerClassName="career-intro__wrapper"
    isCentered
  >
    <section className="career-intro__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
      <hr />
    </section>
  </Section>
)

export default CareerIntro
