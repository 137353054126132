import React from 'react'
import { Section } from '../../../../index'

const LandingThanks2 = props => {
  const {
    node: { headline, subheadline },
  } = props

  return (
    <Section className="new-landing-thanks" innerClassName="new-landing-thanks__wrapper">
      <div className="new-landing-thanks__content">
        <h1 className="new-landing-thanks__headline">{headline}</h1>
        <h2 className="new-landing-thanks__subheadline">{subheadline}</h2>
        <a className="new-landing-thanks__button" href="https://skookum.com/services">
          VIEW SERVICES
        </a>
      </div>
      <div className="new-landing-thanks__right" />
    </Section>
  )
}

export default LandingThanks2
