import React from 'react'
import { Section } from '../../../../../index'

const ThanksBody = props => {
  const {
    content: {
      metadata: { link, linkTitle },
    },
    node: { featuredImage, headline, subheadline },
  } = props

  return (
    <Section className="thanks-body" innerClassName="thanks-body__wrapper">
      <section className="thanks-body__content">
        <h1 className="thanks-body__title">{headline}</h1>
        <h2 className="thanks-body__subtitle">{subheadline}</h2>
        <a className="thanks-body__button" href={link} target="_blank" rel="noopener noreferrer">
          {linkTitle}
        </a>
      </section>
      <section className="thanks-body__background">
        <div className="thanks-body__background--wrapper">
          {featuredImage.map(({ id, file: { url }, title }) => (
            <img key={id} className="thanks-body__image" src={url} alt={title} />
          ))}
        </div>
      </section>
    </Section>
  )
}
export default ThanksBody
