import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const LandingIntro2 = props => {
  const {
    content: { markdown, images },
  } = props

  return (
    <Section className="new-landing-intro" innerClassName="new-landing-intro__wrapper" isCentered>
      <div className="new-landing-intro__left">
        <ReactMarkdown className="new-landing-intro__markdown" escapeHtml={false} source={markdown.content} />
      </div>
      <div className="new-landing-intro__right">
        <img className="new-landing-intro__image" alt={images[0].title} src={images[0].file.url} key={images[0].id} />
      </div>
    </Section>
  )
}

export default LandingIntro2
