import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const NewStudyIntro = props => {
  const {
    content: { data },
    node: { headline },
  } = props

  return (
    <Section className="new-study-intro" innerClassName="new-study-intro__container">
      <div className="new-study-intro__wrapper">
        <section className="new-study-intro__left">
          <ReactMarkdown
            className={classNames('new-study-intro__left-info', {
              'new-study-intro__left-info--two': headline === 'High Point Market:',
            })}
            escapeHtml={false}
            source={data[0].markdown.content}
          />
        </section>
        <section className="new-study-intro__right">
          <ReactMarkdown className="new-study-intro__right-info" escapeHtml={false} source={data[1].markdown.content} />
        </section>
      </div>
    </Section>
  )
}

export default NewStudyIntro
