import React from 'react'
import cx from 'classnames'
import { Section } from '../../../../index'

const LandingClients = props => {
  const {
    content: { images },
  } = props

  return (
    <Section className="landing-clients" innerClassName="landing-clients__wrapper" isCentered>
      <div className="landing-clients__items">
        {images.map(({ id, title, file: { url } }, index) => (
          <div
            key={id}
            className={cx('landing-clients__item', {
              'landing-clients__item--first': index === 0,
              // 'landing-clients__item--second': index === 1,
              'landing-clients__item--last': index === 4,
            })}
          >
            <img key={id} alt={title} src={url} className="landing-clients__image" />
          </div>
        ))}
      </div>
    </Section>
  )
}

export default LandingClients
