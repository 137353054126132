/* eslint-disable jsx-a11y/no-static-element-interactions, no-invalid-this, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */
import React from 'react'
import classNames from 'classnames'
import { Section } from '../../../../index'

const TECH_TALKS = 1748533
const DEVELOPERS = 22915123
const IOS_DEVELOPERS = 1262368

class BlockEvents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterType: 'all',
    }
  }

  onTypeClick(filterType) {
    this.setState({
      filterType,
    })
  }

  render() {
    const {
      events,
      content: { images, metadata },
    } = this.props
    const { filterType } = this.state
    return (
      <Section className={classNames('events', metadata ? metadata.className : '')} isCentered>
        {events.length !== 0 && (
          <section className="events__wrapper">
            <section id="filter" className="events__filters">
              <div className="events__filters-type">
                <a
                  className={classNames('events__buttons-all-type', {
                    'events__buttons-all-type--active': filterType === 'all',
                  })}
                  onClick={() => {
                    this.onTypeClick('all')
                  }}
                >
                  ALL EVENTS
                </a>
                <a
                  className={classNames('events__buttons-skookum', {
                    'events__buttons-skookum--active': filterType === TECH_TALKS,
                  })}
                  onClick={() => {
                    this.onTypeClick(TECH_TALKS)
                  }}
                >
                  TECH TALKS
                </a>
                <a
                  className={classNames('events__buttons-google', {
                    'events__buttons-google--active': filterType === DEVELOPERS,
                  })}
                  onClick={() => {
                    this.onTypeClick(DEVELOPERS)
                  }}
                >
                  DEVELOPERS
                </a>
                <a
                  className={classNames('events__buttons-ios', {
                    'events__buttons-ios--active': filterType === IOS_DEVELOPERS,
                  })}
                  onClick={() => {
                    this.onTypeClick(IOS_DEVELOPERS)
                  }}
                >
                  IOS DEVELOPERS
                </a>
              </div>
            </section>
            <div className="events__items">
              {events.map(
                event =>
                  (filterType === 'all' || filterType === event.group.id) && (
                    <div
                      key={event.id}
                      className={classNames('events__item', {
                        'events__item--google': event.group.id === DEVELOPERS,
                        'events__item--ios': event.group.id === IOS_DEVELOPERS,
                      })}
                      itemScope
                      itemType="http://schema.org/Event"
                    >
                      <div className="events__item-top">
                        {images && images.length !== 0 && (
                          <img className="events__item-icon" src={images[0].file.url} alt="location icon" />
                        )}
                        <h2 className="events__item-location" itemProp="location">
                          {event.group.localized_location}
                        </h2>
                      </div>
                      <span className="events__item-date" itemProp="startDate">
                        {event.local_date}
                      </span>
                      <a
                        className="events__item-title"
                        itemProp="name"
                        href={event.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {event.name}
                      </a>
                      <p className="events__item-description" href={event.link}>
                        {event.group.name}
                      </p>
                      <a
                        className="events__cta"
                        itemProp="url"
                        href={event.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        RSVP
                      </a>
                    </div>
                  ),
              )}
            </div>
          </section>
        )}
      </Section>
    )
  }
}

export default BlockEvents
