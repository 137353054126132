import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Navigation, Section } from '../../../index'

const Header = props => {
  const {
    content: {
      markdown,
      linkGroups,
      metadata: { className },
    },
    node: {
      featuredImage,
      headline,
      subheadline,
      metadata: { isCaseStudy },
    },
    location,
  } = props

  return (
    <header
      className={classNames('page-header', {
        [`page-header--${className}`]: className,
      })}
    >
      {linkGroups.map(group => (
        <Navigation key={group.id} links={group.links} location={location} />
      ))}

      {featuredImage && (
        <div
          className={classNames('page-header__wrapper', {
            [`page-header__wrapper--${className}`]: className,
          })}
        >
          {featuredImage.map(({ id, title, sizes }) => (
            <img
              key={id}
              className={classNames('page-header__image', className)}
              src={`${sizes.src}&fl=progressive`}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              sizes="100vw"
              alt={title}
            />
          ))}

          {(markdown || isCaseStudy) && (
            <section
              className={classNames('page-header__content', { 'page-header__content--study': isCaseStudy }, className)}
            >
              {markdown && !isCaseStudy && <ReactMarkdown escapeHtml={false} source={markdown.content} />}

              {isCaseStudy && (
                <div className="page-header__study-content">
                  <h2>{headline}</h2>
                  <p>{subheadline}</p>
                </div>
              )}
            </section>
          )}
        </div>
      )}

      {!featuredImage && markdown && (
        <Section isCentered innerClassName="page-header__content page-header__content--imageless">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </Section>
      )}
    </header>
  )
}

export default Header
