/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'
import ReactMarkdown from 'react-markdown'
import cx from 'classnames'
import { Section } from '../../../../index'

const LandingInfo = props => {
  const {
    content: {
      data,
      metadata: { headline },
    },
  } = props

  return (
    <Section className="new-landing-info" innerClassName="new-landing-info__wrapper" isCentered>
      <div className="new-landing-info__head">
        <h1 className="new-landing-info__title">{headline}</h1>
      </div>
      <div className="new-landing-info__items">
        {data.map(({ id, markdown, images }, index) => (
          <div
            key={id}
            className={cx('new-landing-info__item', {
              'new-landing-info__item--first': index === 0,
              'new-landing-info__item--second': index === 1,
              'new-landing-info__item--third': index === 2,
            })}
          >
            <img
              className="new-landing-info__image"
              alt={images[0].title}
              src={images[0].file.url}
              key={images[0].id}
            />
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </div>
        ))}
      </div>
      <a className="landing-success__button" href="#">
        REQUEST A FREE CONSULTATION
      </a>
    </Section>
  )
}

export default LandingInfo
