import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const HomeServices = ({ content }) => {
  const { metadata } = content
  return (
    <Section className={classNames('services', metadata ? metadata.className : '')} withPadding isCentered>
      <h2 className="services__title">{metadata.headline}</h2>
      <p className="services__subheadline">{metadata.subheadline}</p>

      <div className="services__items">
        {content.data.map(({ id, name, markdown, images }, index) => (
          <div
            key={id}
            className={classNames('services__item', {
              'services__item--border': index === 1,
            })}
          >
            {images.map(({ title, file }) => (
              <img key={id} className="services__item-icon" src={file.url} alt={title} />
            ))}
            <h3 className="services__item-title">{name}</h3>
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </div>
        ))}
      </div>

      <a className="button services__link" href={metadata.link}>
        {metadata.linkTitle}
      </a>
    </Section>
  )
}

export default HomeServices
