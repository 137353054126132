/* eslint-disable jsx-a11y/no-static-element-interactions, no-invalid-this, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */
import React from 'react'
import classNames from 'classnames'
import { Section } from '../../../../index'

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 2,
    }
  }

  onArrowClick(arrowDirection) {
    const { content } = this.props
    const { length } = content.images
    const { currentIndex } = this.state

    if (arrowDirection === 'right' && currentIndex < length - 1) {
      this.setState({
        currentIndex: currentIndex + 1,
      })
    }

    if (arrowDirection === 'left' && currentIndex > 2) {
      this.setState({
        currentIndex: currentIndex - 1,
      })
    }
  }

  render() {
    const {
      content: { images },
      node: { headline },
    } = this.props
    const { currentIndex } = this.state
    return (
      <Section className="carousel">
        <div className="carousel__images">
          {images.map(
            image =>
              images.indexOf(image) > 1 && (
                <img
                  className={classNames('carousel__image', {
                    'carousel__image--left': currentIndex > images.indexOf(image),
                    'carousel__image--center': currentIndex === images.indexOf(image),
                    'carousel__image--right': currentIndex < images.indexOf(image),
                    'carousel__image--slim': headline === 'High Point Market:',
                  })}
                  key={image.id}
                  alt={image.title}
                  src={image.file.url}
                  style={{
                    zIndex:
                      images.indexOf(image) - currentIndex > 0
                        ? (images.indexOf(image) - currentIndex) * -10
                        : (images.indexOf(image) - currentIndex) * 10,
                  }}
                />
              ),
          )}
        </div>
        <div className="carousel__arrows">
          <a
            className="carousel__arrow"
            onClick={() => {
              this.onArrowClick('left')
            }}
          >
            <img
              className={classNames('carousel__arrow', {
                'carousel__arrow--inactive': currentIndex === 2,
              })}
              alt={images[0].title}
              src={images[0].file.url}
            />
          </a>
          <p className="carousel__index">
            {`${currentIndex - 1}`}
            {'-'}
            {`${images.length - 2}`}
          </p>
          <a
            className="carousel__arrow"
            onClick={() => {
              this.onArrowClick('right')
            }}
          >
            <img
              className={classNames('carousel__arrow', {
                'carousel__arrow--inactive': currentIndex === images.length - 1,
              })}
              alt={images[1].title}
              src={images[1].file.url}
            />
          </a>
        </div>
      </Section>
    )
  }
}

export default Carousel
