import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { BlogCard, BlogPagination, Section } from '../../../../index'

const BlogGrid = props => {
  const {
    content: { data },
    paginate,
  } = props

  return (
    <Section className="blog-grid" isCentered>
      <header className="blog-grid__header">
        <h2 className="blog-grid__title">Our Blog</h2>
        <ul className="list-filter">
          <li className="list-filter__item">
            <Link
              className={cx('list-filter__link', {
                'list-filter__link--active': typeof window !== 'undefined' && !window.location.pathname.includes('tag'),
              })}
              to="/blog"
            >
              All Posts
            </Link>
          </li>
          {data.map(fields => (
            <li key={fields.slug} className="list-filter__item">
              <Link
                className={cx('list-filter__link', {
                  'list-filter__link--active':
                    typeof window !== 'undefined' && window.location.pathname.includes(fields.slug),
                })}
                to={`/blog/tags/${fields.slug}`}
              >
                {fields.name}
              </Link>
            </li>
          ))}
        </ul>
      </header>

      <div className="blog-grid__items">
        {paginate.items.map(item => (
          <BlogCard key={item.slug} {...item} />
        ))}
      </div>

      <BlogPagination {...paginate} />
    </Section>
  )
}

export default BlogGrid
