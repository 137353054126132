/* eslint-disable jsx-a11y/no-static-element-interactions, no-invalid-this, jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */

import React from 'react'
import cx from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

class LandingSuccess extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currIndex: 0,
    }
  }

  onArrowClick(arrowDirection) {
    const { content } = this.props
    const { currIndex } = this.state

    if (arrowDirection === 'right' && currIndex < content.data.length - 1) {
      this.setState({
        currIndex: currIndex + 1,
      })
    } else if (arrowDirection === 'right' && currIndex === content.data.length - 1) {
      this.setState({
        currIndex: 0,
      })
    }

    if (arrowDirection === 'left' && currIndex > 0) {
      this.setState({
        currIndex: currIndex - 1,
      })
    } else if (arrowDirection === 'left' && currIndex === 0) {
      this.setState({
        currIndex: content.data.length - 1,
      })
    }
  }

  render() {
    const {
      content: { data },
    } = this.props
    const { currIndex } = this.state
    return (
      <Section className="landing-success" innerClassName="landing-success__wrapper" isCentered>
        <div className="landing-success__item">
          <div className="landing-success__content">
            <div className="landing-success__left">
              <div className="landing-success__logo">
                <img
                  src={`${data[currIndex].companyLogo.file.url}?h=51&fit=pad`}
                  alt={`${data[currIndex].companyLogo.title}`}
                  key={data[currIndex].companyLogo.id}
                />
              </div>
              <div className="landing-success__mark">
                <ReactMarkdown escapeHtml={false} source={data[currIndex].markdown.content} key={data[currIndex].id} />
                <img
                  className="landing-success__image--mobile"
                  alt={data[currIndex].applicationScreenshot.title}
                  src={data[currIndex].applicationScreenshot.file.url}
                  key={data[currIndex].applicationScreenshot.id}
                />
                <a className="landing-success__button" href="#">
                  REQUEST A FREE CONSULTATION
                </a>
              </div>
            </div>
            <div className="landing-success__right">
              <img
                className="landing-success__image--desktop"
                alt={data[currIndex].applicationScreenshot.title}
                src={`${data[currIndex].applicationScreenshot.file.url}?w=343&h=646&fit=pad`}
                key={data[currIndex].applicationScreenshot.id}
              />
            </div>
          </div>
          <div className={cx('landing-success__carousel-index', { inactive: data.length === 1 })}>
            <a
              className="landing-success__left-arrow"
              onClick={() => {
                this.onArrowClick('left')
              }}
            >
              <span className="landing-success__left-arrow-icon" />
              Prev
            </a>
            <span className="carousel-counter">{`${currIndex + 1} of ${data.length}`}</span>
            <a
              className="landing-success__right-arrow"
              onClick={() => {
                this.onArrowClick('right')
              }}
            >
              Next
              <span className="landing-success__right-arrow-icon" />
            </a>
          </div>
        </div>
      </Section>
    )
  }
}

export default LandingSuccess
