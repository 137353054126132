import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const StoryContent = ({ content: { markdown, metadata } }) => (
  <Section className={classNames('story', metadata ? metadata.className : '')} withPadding isCentered>
    <section className="story__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </section>
  </Section>
)

export default StoryContent
