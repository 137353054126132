import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { Section } from '../../../../index'

const AboutCta = props => {
  const {
    content: { markdown, images, metadata },
  } = props

  return (
    <Section
      className={classNames('about-cta', metadata ? metadata.className : '')}
      innerClassName="about-cta__wrapper"
    >
      {images &&
        images.map(({ id, title, sizes }) => (
          <img
            key={id}
            className="about-cta__image"
            src={sizes.src}
            srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
            sizes="100vw"
            alt={title}
          />
        ))}
      <div className="about-cta__content">
        {markdown && <ReactMarkdown escapeHtml={false} source={markdown.content} />}
        <Link to={metadata.link}>{metadata.linkTitle}</Link>
      </div>
    </Section>
  )
}

export default AboutCta
