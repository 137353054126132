import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const ProfileRight = props => {
  const {
    content: { markdown, images, metadata },
  } = props
  return (
    <Section
      className={classNames('profile-right', metadata && metadata.className)}
      innerClassName="intro__wrapper"
      isCentered
    >
      <hr />
      <section className="profile-right__markdown">
        <ReactMarkdown escapeHtml={false} source={markdown.content} />
      </section>
      <section className="profile-right__images">
        <div className="profile-right__right">
          {images.map(({ id, title, sizes }) => (
            <img
              key={id}
              className="profile-right__image"
              src={sizes.src}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              sizes="50vw"
              alt={title}
            />
          ))}
        </div>
      </section>
    </Section>
  )
}

export default ProfileRight
