import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const ServiceOfferings = props => {
  const {
    content: { data, name },
  } = props

  return (
    <Section className="services-offerings" innerClassName="services-offerings__wrapper" isCentered>
      <h3 className="services-offerings__title">{name}</h3>
      <div className="services-offerings__content">
        {data.map((offering, index) => (
          <section
            key={offering.id}
            className={classNames('services-offerings__block', {
              'services-offerings__block--right': index % 2 === 1,
            })}
          >
            <ReactMarkdown escapeHtml={false} source={offering.markdown.content} />
          </section>
        ))}
      </div>
    </Section>
  )
}

export default ServiceOfferings
