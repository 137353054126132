import React from 'react'
import classNames from 'classnames'
import { Navigation } from '../../../../index'

class LandingHeader extends React.Component {
  constructor() {
    super()

    this.state = {
      isVisible: true,
    }
  }

  componentDidMount() {
    const script = document.createElement('script')

    script.id = 'hsform'
    script.src = '//js.hsforms.net/forms/v2.js'
    script.async = true

    if (!document.querySelector('#hsform')) {
      document.body.appendChild(script)
    }

    this.initialize()
  }

  initialize() {
    const {
      content: { metadata },
      node: { slug },
    } = this.props
    if (window.hbspt) {
      const target = '#landing-header__form'
      const inlineMessage = `
        <div class="after-submit__content">
          <h3 class="after-submit__title">${metadata.title || 'Thanks!'}</h1>
          <p class="after-submit__text">${metadata.subtitle || 'One of our teammates will be in touch shortly.'}</p>
          <img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=50127&conversionId=354834&fmt=gif" />
          ${
            metadata.link
              ? `<a class="after-submit__download" href="/${metadata.link}" target="_blank" rel="noopener noreferrer">Download</a>`
              : ''
          }
        </div>
      `
      window.hbspt.forms.create({
        target,
        inlineMessage,
        css: '',
        cssClass: 'landing-header__form',
        submitButtonClass: 'button',
        portalId: '2886218',
        formId: metadata.formId,
        onFormSubmit: () => {
          if (window.fbq) {
            window.fbq('track', 'Lead')
          }
          window.location = `/${slug}/thank-you`
        },
      })
    } else {
      setTimeout(this.initialize.bind(this), 50)
    }
  }

  render() {
    const {
      content: {
        linkGroups,
        metadata: { className },
      },
      node: { featuredImage, headline, subheadline },
      location,
    } = this.props
    const { isVisible } = this.state

    return (
      <header
        className={classNames('landing-header', {
          [`landing-header--${className}`]: className,
        })}
      >
        {linkGroups.map(group => (
          <Navigation key={group.id} links={group.links} location={location} isAbsolute />
        ))}

        {featuredImage && (
          <div className="landing-header__wrapper">
            <section className={classNames('landing-header__content', className)}>
              <div className={classNames('landing-header__content-inner', className)}>
                <h1 className="landing-header__title">{headline}</h1>
                <h2
                  className={classNames('landing-header__subtitle', {
                    'landing-header__subtitle--hidden': !isVisible,
                  })}
                >
                  {subheadline}
                </h2>
                <div id="landing-header__form" />
              </div>
            </section>

            {featuredImage.map(({ id, title, sizes }) => (
              <div key={id} className="landing-header__img-wrapper">
                <img
                  className={classNames('landing-header__image', className)}
                  src={`${sizes.src}&fl=progressive`}
                  srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
                  sizes="100vw"
                  alt={title}
                />
              </div>
            ))}
          </div>
        )}
      </header>
    )
  }
}

export default LandingHeader
