import React from 'react'
import classNames from 'classnames'

const Section = props => {
  const {
    className,
    children,
    innerClassName,
    isCentered,
    style,
    withMargin,
    withPadding,
    withOffsetBackground,
  } = props
  return (
    <section
      style={style}
      className={classNames('section', {
        'section--margin': withMargin,
        'section--padding': withPadding,
        'section--offsetBackground': withOffsetBackground,
        [className]: className,
      })}
    >
      <div
        className={classNames({
          section__inner: isCentered, // eslint-disable-line camelcase
          [innerClassName]: innerClassName,
        })}
      >
        {children}
      </div>
    </section>
  )
}

export default Section
