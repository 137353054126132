import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const Awards = ({ content }) => {
  const { metadata } = content
  return (
    <Section className={classNames('awards', metadata ? metadata.className : '')} isCentered>
      <div className="awards__ribbon">
        {content.data.map(({ id, images }, index) => (
          <div key={id} className="awards__image">
            {images.map(
              ({ title, file }) => index === 0 && <img key={id} className="awards__icon" src={file.url} alt={title} />,
            )}
          </div>
        ))}
      </div>
      <h2 className="awards__title">{metadata.headline}</h2>
      <p className="awards__subheadline">{metadata.subheadline}</p>

      <div className="awards__items">
        {content.data.map(({ id, markdown }, index) => (
          <div
            key={id}
            className={classNames('awards__item', {
              'awards__item--border': index === 1,
            })}
          >
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </div>
        ))}
      </div>
      <hr />
    </Section>
  )
}

export default Awards
