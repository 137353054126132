import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const AboutCollage = props => {
  const {
    content: { images, markdown, metadata },
  } = props

  return (
    <Section className={classNames(metadata ? metadata.className : '')} isCentered>
      <section className="about-collage__grid">
        <div className="about-collage__column">
          {images.slice(0, 1).map(({ id, sizes, title }) => (
            <img
              key={id}
              className="about-collage__image"
              src={sizes.src}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              alt={title}
              sizes="33vw"
            />
          ))}
        </div>
        <div className="about-collage__column about-collage__column--small">
          <div className="about-collage__content">
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </div>
          {images.slice(1, 2).map(({ id, sizes, title }) => (
            <img
              key={id}
              className="about-collage__image about-collage__image--half about-collage__image--space"
              src={sizes.src}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              alt={title}
              sizes="33vw"
            />
          ))}
        </div>
        <div className="about-collage__column">
          {images.slice(2).map(({ id, sizes, title }, index) => (
            <img
              key={id}
              className={classNames('about-collage__image about-collage__image--half', {
                'about-collage__image--space': index === 1,
              })}
              src={sizes.src}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              alt={title}
              sizes="33vw"
            />
          ))}
        </div>
      </section>
    </Section>
  )
}

export default AboutCollage
