import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../index'

const BlockContent = ({
  content: {
    markdown,
    metadata: { className },
  },
}) => (
  <Section isCentered className={className}>
    <section>
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </section>
  </Section>
)

export default BlockContent
