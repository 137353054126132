import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const NewStudySolution = props => {
  const {
    content: { markdown, images },
    node: { headline },
  } = props
  return (
    <Section className="new-study-solution" isCentered>
      <section className="new-study-solution__wrapper">
        <div className="new-study-solution__images">
          {images.map(({ id, title, file }) => (
            <img
              key={id}
              className={classNames('new-study-solution__image', {
                'new-study-solution__image--service': headline !== 'Charlotte Mecklenburg Library:',
              })}
              src={file.url}
              alt={title}
            />
          ))}
        </div>
        <div className="new-study-solution__markdown">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </div>
      </section>
    </Section>
  )
}

export default NewStudySolution
