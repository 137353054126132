import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const LandingSolutions = props => {
  const {
    content: {
      data,
      metadata: { headline, subheadline },
    },
  } = props

  return (
    <Section className="landing-solutions" innerClassName="landing-solutions__wrapper" isCentered>
      <div className="landing-solutions__head">
        <h1 className="landing-solutions__title">{headline}</h1>
        <h2 className="landing-solutions__subtitle">{subheadline}</h2>
      </div>
      <div className="landing-solutions__items">
        {data.map(({ id, markdown, images }) => (
          <div key={id} className="landing-solutions__content">
            <div className="landing-solutions__left">
              <ReactMarkdown
                escapeHtml={false}
                source={markdown.content}
                key={id}
                className="landing-solutions__mark"
              />
            </div>
            <div className="landing-solutions__right">
              <img
                className="landing-solutions__image"
                alt={images[0].title}
                src={images[0].file.url}
                key={images[0].id}
              />
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default LandingSolutions
