import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const QuoteBlock = ({ content: { markdown } }) => (
  <Section className="quote" withPadding isCentered>
    <section className="quote__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </section>
  </Section>
)

export default QuoteBlock
