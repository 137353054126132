import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import get from 'lodash/get'
import { Section } from '../../../../index'

const CaseBlock = props => {
  const {
    content: { markdown, images, metadata },
  } = props
  const reverse = get(metadata, 'reverse')

  return (
    <Section
      className={classNames('offering', metadata ? metadata.className : '')}
      innerClassName={classNames('offering__wrapper', {
        'offering__wrapper--reverse': reverse,
      })}
      isCentered
    >
      <div className="offering__casestudy offering__casestudy--left">
        {images.map(({ id, title, sizes }, index) => (
          <img
            className={classNames('offering__background', {
              'offering__background--left': !reverse,
              'offering__background--right': reverse,
              'offering__background--reverse': reverse,
              'offering__background--secondary': index > 0,
            })}
            key={id}
            src={sizes.src.split('fm=jpg')}
            srcSet={sizes.srcSet
              .split('fm=jpg')
              .join('') // original format
              .split('q=75')
              .join('q=100')}
            alt={title}
            sizes="50vw"
          />
        ))}
      </div>
      <section
        className={classNames('offering__markdown', {
          'offering__markdown--reverse': reverse,
        })}
      >
        <ReactMarkdown escapeHtml={false} source={markdown.content} />
      </section>
    </Section>
  )
}

export default CaseBlock
