import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const ClientLogos = props => {
  const {
    content: { markdown, images, metadata },
  } = props

  return (
    <Section
      className={classNames('clients', metadata ? metadata.className : '')}
      innerClassName="clients__wrapper"
      isCentered
    >
      <header className="clients__header">
        <div className="clients__header-inner">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </div>
      </header>
      <section className="clients__content">
        <ul className="clients__row">
          {images.slice(0, 9).map(({ id, description, title, file }) => (
            <li key={id} className="client__logo">
              <img
                className={classNames('client__image', `client__image--${title.toLowerCase()}`)}
                src={file.url}
                alt={title}
              />
              <span className="screen-reader">{description}</span>
            </li>
          ))}
        </ul>
      </section>
    </Section>
  )
}

export default ClientLogos
