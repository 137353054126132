import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const ServiceHelp = ({ content }) => {
  const { metadata } = content

  return (
    <Section className="service-help" isCentered>
      <h2 className="service-help__title">{metadata.headline}</h2>
      <div className="service-help__items">
        {content.data.map(({ id, markdown }, index) => (
          <div
            key={id}
            className={classNames('service-help__item', {
              'service-help__item--first': index === 0,
              'service-help__item--second': index === 1,
              'service-help__item--third': index === 2,
            })}
          >
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </div>
        ))}
      </div>
      <hr />
    </Section>
  )
}

export default ServiceHelp
