import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const Deliverables = props => {
  const {
    content: { data, metadata },
  } = props

  return (
    <Section
      className={classNames('deliverables', metadata ? metadata.className : '')}
      innerClassName="deliverables__wrapper"
      isCentered
      withPadding
    >
      <section className="deliverables__items">
        {data.map(deliverable => (
          <div key={deliverable.id} className="deliverable">
            {(deliverable.images || []).map(image => (
              <i
                className="deliverable__icon"
                key={image.id}
                style={{
                  backgroundImage: `url(${image.file.url}?w=200)`,
                }}
              />
            ))}

            <ReactMarkdown escapeHtml={false} source={deliverable.markdown.content} />
          </div>
        ))}
      </section>
    </Section>
  )
}

export default Deliverables
