import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const LandingCta = ({ content: { markdown } }) => (
  <Section className="landing-cta" innerClassName="landing-cta__inner" withPadding isCentered>
    <section className="landing-cta__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </section>
  </Section>
)

export default LandingCta
