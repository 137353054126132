import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const Intro = props => {
  const {
    content: { markdown, images, metadata },
  } = props
  return (
    <Section className={classNames('intro', metadata ? metadata.className : '')} innerClassName="intro__wrapper">
      <section className="intro__images">
        <div className="intro__row intro__row--first">
          {images.slice(0, 2).map(({ id, title, sizes }) => (
            <img
              key={id}
              className="intro__image"
              src={sizes.src}
              srcSet={sizes.srcSet
                .split('fm=jpg')
                .join('fm=jpg&fl=progressive')
                .split('q=75')
                .join('q=90')}
              sizes="25vw"
              alt={title}
            />
          ))}
        </div>
        <div className="intro__row intro__row--indented">
          {images.slice(2, 3).map(({ id, title, sizes }, index) => (
            <img
              key={id}
              className={classNames('intro__image', {
                'intro__image--indented': index === 0,
              })}
              src={sizes.src}
              srcSet={sizes.srcSet
                .split('fm=jpg')
                .join('fm=jpg&fl=progressive')
                .split('q=75')
                .join('q=90')}
              sizes="50vw"
              alt={title}
            />
          ))}
        </div>
      </section>
      <section className="intro__markdown">
        <ReactMarkdown escapeHtml={false} source={markdown.content} />
      </section>
    </Section>
  )
}

export default Intro
