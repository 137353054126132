import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { Navigation, Section } from '../../../../index'

const BlogPostHeader = props => {
  const {
    content: {
      linkGroups,
      metadata: { className },
    },
    post,
    next,
    previous,
    location,
  } = props

  if (!post) {
    return null
  }

  return (
    <header
      className={classNames('page-header', 'page-header--post', {
        [`page-header--${className}`]: className,
      })}
    >
      {linkGroups.map(group => (
        <Navigation key={group.id} links={group.links} location={location} />
      ))}

      <Section className="page-header__post-header" innerClassName="page-header__post-inner">
        <section className="page-header__post-container">
          <time className="page-header__time">{post.published_at}</time>
          <h1 className="page-header__post-title">{post.title}</h1>
          <span className="page-header__post-author">{post.author.name}</span>
        </section>
        <section className="page-header__nav">
          {next && (
            <Link to={`/blog/${next.slug}`} className="page-header__nav-block">
              <span className="page-header__direction">Next Post</span>
              <h3 className="page-header__nav-title">{next.title}</h3>
            </Link>
          )}

          {previous && (
            <Link to={`/blog/${previous.slug}`} className="page-header__nav-block">
              <span className="page-header__direction">Previous Post</span>
              <h3 className="page-header__nav-title">{previous.title}</h3>
            </Link>
          )}
        </section>
        <div
          className={classNames('page-header__wrapper', 'page-header__wrapper--post', {
            [`page-header__wrapper--post-${post.metadata.className}`]: post.metadata.className,
          })}
        >
          <img
            className={classNames('page-header__image', {
              [`page-header__image-${post.metadata.className}`]: post.metadata.className,
            })}
            src={post.image.sizes.src}
            srcSet={post.image.sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
            sizes="100vw"
            alt={post.image.title}
          />
        </div>
      </Section>
    </header>
  )
}

export default BlogPostHeader
