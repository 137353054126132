import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const CaseStudy = props => {
  const {
    node: { intro, markdown, quote, citation },
  } = props

  return (
    <Section className="study" innerClassName="study__inner" isCentered withPadding>
      <div className="study__intro">
        <h2 className="study__summary-title">Client Summary</h2>
        <p className="study__summary-text">{intro.content}</p>
      </div>

      <div className="study__details">
        <ReactMarkdown escapeHtml={false} source={markdown.content} />
      </div>

      {quote && (
        <div className="study__testimonial">
          <blockquote className="study__quote">
            <ReactMarkdown escapeHtml={false} source={quote.content} />
          </blockquote>
          <cite className="study__citation">{citation}</cite>
        </div>
      )}
    </Section>
  )
}

export default CaseStudy
