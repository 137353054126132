import React from 'react'
import cx from 'classnames'
import { Icons } from '../../../../index'

class LandingHeader2 extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')

    script.id = 'hsform'
    script.src = '//js.hsforms.net/forms/v2.js'
    script.async = true

    if (!document.querySelector('#hsform')) {
      document.body.appendChild(script)
    }

    this.initialize()
  }

  initialize() {
    const {
      content: { metadata },
    } = this.props
    if (window.hbspt) {
      const target = '#new-landing-header__form'
      window.hbspt.forms.create({
        target,
        css: '',
        cssClass: 'new-landing-header__form',
        submitButtonClass: 'button',
        portalId: '2886218',
        formId: metadata.formId,
        onFormSubmit: () => {
          if (window.fbq) {
            window.fbq('track', 'Lead')
            // window.location = `/${slug}/thank-you`
          }
        },
      })
    } else {
      setTimeout(this.initialize.bind(this), 50)
    }
  }

  render() {
    const {
      content: {
        metadata: { className, title, link },
      },
      node: { headline, subheadline },
    } = this.props

    return (
      <header
        className={cx('new-landing-header', {
          [`new-landing-header--${className}`]: className,
        })}
      >
        <div className="new-landing-header__wrapper">
          <section className="new-landing-header__content">
            <div className="new-landing-header__content-inner">
              <a title="Home" className="navigation-logo" href="https://skookum.com">
                <Icons.Logo />
              </a>
              <h1 className="new-landing-header__title">{headline}</h1>
              <h2 className="new-landing-header__subtitle">{subheadline}</h2>
              <a className="new-landing-header__button" href={link}>
                LEARN MORE
              </a>
            </div>
          </section>

          <div className="new-landing-header__right">
            <div className="new-landing-header__box">
              <h1 className="new-landing-header__ftitle">{title}</h1>
              <div id="new-landing-header__form" />
            </div>
          </div>
        </div>
      </header>
    )
  }
}

//    {linkGroups.map(group => <Navigation key={group.id} links={group.links} location={location} isAbsolute />)}

export default LandingHeader2
