import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const NewCaseHeader = props => {
  const {
    content: { markdown, images },
    node: { headline, subheadline },
  } = props

  return (
    <Section className="new-case-header__post-header" innerClassName="new-case-header__post-inner">
      <section className="new-case-header__content">
        <div
          className={classNames('new-case-header__title', {
            'new-case-header__title--service': headline === 'Service Pros:',
          })}
        >
          <h1 className="new-case-header__headline">{headline}</h1>
          <h1 className="new-case-header__subheadline">{subheadline}</h1>
        </div>
        <div className="new-case-header__markdown">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </div>
      </section>
      {images &&
        images.map(({ id, title, sizes }) => (
          <img
            key={id}
            className="new-case-header__image"
            src={`${sizes.src}&fl=progressive`}
            srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
            sizes="100vw"
            alt={title}
          />
        ))}
    </Section>
  )
}

export default NewCaseHeader
