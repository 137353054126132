import React from 'react'
import classNames from 'classnames'
import { Section } from '../../../../index'

const LocationEvents = ({ events, content: { images, headline, metadata }, node: { name } }) => {
  const cEvents = events.slice()
  const dEvents = events.slice()
  let eSelect = events

  let i = 0
  let dNum = 0
  let cNum = 0

  for (i = 0; i < events.length; i += 1) {
    if (events[i].localized_location === 'Charlotte, NC' && cNum < 3) {
      cEvents[cNum] = events[i]
      cNum += 1
    } else if (events[i].localized_location === 'Denver, CO' && dNum < 3) {
      dEvents[dNum] = events[i]
      dNum += 1
    }
  }

  if (name === 'Charlotte') {
    eSelect = cEvents
  } else {
    eSelect = dEvents
  }

  return (
    <Section className={classNames('location-events', metadata ? metadata.className : '')} isCentered>
      {events.length !== 0 && (
        <section className="location-events__wrapper">
          <h1 className="location-events__headline">{headline}</h1>
          <div className="location-events__items">
            {eSelect.map(
              (event, index) =>
                index < 3 && (
                  <div
                    key={event.id}
                    className={classNames('location-events__item', {
                      'location-events__item--denver': event.localized_location === 'Denver, CO',
                    })}
                    itemScope
                    itemType="http://schema.org/Event"
                  >
                    <div className="location-events__item-top">
                      {images && images.length !== 0 && (
                        <img className="location-events__item-icon" src={images[0].file.url} alt="location icon" />
                      )}
                      <h2 className="location-events__item-location" itemProp="location">
                        {event.group.localized_location}
                      </h2>
                    </div>
                    <span className="location-events__item-date" itemProp="startDate">
                      {event.local_date}
                    </span>
                    <a
                      className="location-events__item-title"
                      itemProp="name"
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event.name}
                    </a>
                    <p className="location-events__item-description" href={event.link}>
                      {event.group.name}
                    </p>
                    <a
                      className="location-events__cta"
                      itemProp="url"
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      RSVP
                    </a>
                  </div>
                ),
            )}
          </div>
          <a className="location-events__button" href="/events" target="_blank" rel="noopener noreferrer">
            See All Events
          </a>
        </section>
      )}
    </Section>
  )
}

export default LocationEvents
