import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const IntroCopy = ({ content: { markdown } }) => (
  <Section className="intro-copy" innerClassName="intro-copy__wrapper" isCentered withPadding>
    <ReactMarkdown escapeHtml={false} source={markdown.content} />
  </Section>
)

export default IntroCopy
