import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const CaseStudyChallenge2019 = props => {
  const {
    content: { data },
    metadata,
  } = props
  const [imageStatistic] = data[1].images
  return (
    <Section
      className={classNames('case-study-2019-challenge', metadata && metadata.className)}
      innerClassName="case-study-2019-challenge__wrapper"
      isCentered
    >
      <div className="case-study-2019-challenge__image-wrapper">
        <img
          key={imageStatistic.id}
          className="case-study-2019-challenge__image"
          src={imageStatistic.file.url}
          alt={imageStatistic.title}
        />
        <section className="case-study-2019-challenge__image-content">
          <ReactMarkdown
            escapeHtml={false}
            source={data[1].markdown.content}
            className="case-study-2019-challenge__image-content"
          />
        </section>
      </div>
      <section className="case-study-2019-challenge__content">
        <ReactMarkdown escapeHtml={false} source={data[0].markdown.content} />
      </section>
    </Section>
  )
}
export default CaseStudyChallenge2019
