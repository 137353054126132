import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { Section } from '../../../../index'

const LocationWork = props => {
  const {
    content: {
      data,
      metadata: { headline, className },
    },
  } = props

  return (
    <Section className={classNames('location-work', className)} innerClassName="location-work__wrapper" isCentered>
      <h1 className="location-events__headline">{headline}</h1>
      <section className="location-work__items">
        {data.map(({ id, name, subheadline, slug, featuredImage }) => (
          <div key={id} className="location-work__tile">
            {featuredImage.map(({ sizes, title }) => (
              <Link key={id} to={`/case-study/${slug}`}>
                <div className="location-work__image-wrapper">
                  <img
                    className="location-work__image"
                    src={sizes.src}
                    srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
                    alt={title}
                    sizes="(max-width: 600px) 100vw, 50vw"
                  />
                </div>
              </Link>
            ))}

            <Link to={`/case-study/${slug}`}>
              <h2 className="location-work__title">{name}</h2>
            </Link>
            <p className="location-work__subheadline">{subheadline}</p>
            <Link className="location-work__cta" to={`/case-study/${slug}`}>
              Learn More
            </Link>
          </div>
        ))}
      </section>
      <a className="location-events__button" href="/events" target="_blank" rel="noopener noreferrer">
        See More Projects
      </a>
    </Section>
  )
}

export default LocationWork
