import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { Section } from '../../../../index'

const OfficeLinks = props => {
  const {
    content: { data, metadata },
  } = props

  return (
    <Section
      className={classNames('office-links', metadata ? metadata.className : '')}
      innerClassName="office-links__wrapper"
      isCentered
    >
      <h1 className="office-links__title">Our Offices</h1>
      <div className="office-links__content">
        {data.map(({ id, images, markdown, headline }, index) => (
          <div
            key={id}
            className={classNames('office-links__office', {
              'office-links__office--right': index === 1,
            })}
          >
            <div className="office-links__tile">
              <Link to={headline.toLowerCase()}>
                <img className="office-links__image" src={images[0].file.url} alt="skyline" />
              </Link>
              <a className="office-links__link" href={`/${headline.toLowerCase()}`}>
                {headline}
              </a>
              <ReactMarkdown className="office-links__markdown" escapeHtml={false} source={markdown.content} />
              <Link className="office-links__cta" to={`/${headline.toLowerCase()}`}>
                Learn More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default OfficeLinks
