import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const NewStudyQuote = props => {
  const {
    content: { markdown },
  } = props

  return (
    <Section className="new-study-quote" innerClassName="new-study-quote__wrapper" isCentered>
      <div className="new-study-quote__content">
        <ReactMarkdown className="new-study-quote__quote" escapeHtml={false} source={markdown.content} />
      </div>
    </Section>
  )
}

export default NewStudyQuote
