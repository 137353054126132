import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { Section } from '../../../../index'

const RelatedPost = props => {
  const {
    content: { markdown, images, metadata, headline },
  } = props
  return (
    <Section
      className={classNames('related-post', metadata && metadata.className)}
      innerClassName="related-post__wrapper"
      isCentered
    >
      <h1 className="related-post__title">{headline}</h1>
      <section className="related-post__content">
        <div className="related-post__left">
          <Link to={metadata.link}>
            {images.map(({ id, title, sizes }) => (
              <img
                key={id}
                className="related-post__image"
                src={sizes.src}
                srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
                sizes="50vw"
                alt={title}
              />
            ))}
          </Link>
        </div>
        <div className="related-post__markdown">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
          <Link className="related-post__cta" to={metadata.link}>
            Learn More
          </Link>
        </div>
      </section>
    </Section>
  )
}

export default RelatedPost
