import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const ServiceGrid = props => {
  const {
    content: { data, name, metadata },
  } = props

  return (
    <Section
      className={classNames('services-grid', metadata ? metadata.className : '')}
      innerClassName="services-grid__wrapper"
      isCentered
    >
      <h3 className="services-grid__title">{name}</h3>
      <div className="services-grid__content">
        {data.map(offering => (
          <div key={offering.id} href={`/services/${offering.slug}`} className="services-grid__block">
            {offering.images.map(({ title, sizes }) => (
              <img key={offering.id} className="services-grid__icon" src={sizes.src} alt={title} />
            ))}
            <a href={`/services/${offering.slug}`} className="services-grid__block-title">
              {offering.name}
            </a>
            <ReactMarkdown escapeHtml={false} source={offering.markdown.content} />
            <a href={`/services/${offering.slug}`} className="services-grid__learn">
              Learn More
            </a>
          </div>
        ))}
      </div>
      <hr />
    </Section>
  )
}

export default ServiceGrid
