import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const CaseStudyWork = props => {
  const {
    content: { markdown },
  } = props

  return (
    <Section className="case-work" innerClassName="case-work__wrapper" isCentered>
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </Section>
  )
}

export default CaseStudyWork
