/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */
import React from 'react'
import cx from 'classnames'
import { Link } from 'gatsby'
import { Icons } from '../../../index'

class Navigation extends React.Component {
  constructor() {
    super()

    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { isOpen } = this.state
    const { links, isAbsolute } = this.props
    return (
      <div className="main-header">
        {isAbsolute ? (
          <a title="Home" className="navigation-logo" href="https://skookum.com">
            <Icons.Logo />
          </a>
        ) : (
          <Link title="Home" className="navigation-logo" to="/">
            <Icons.Logo />
          </Link>
        )}
        <nav className={cx('main-nav', { visible: isOpen })}>
          {links
            .filter(({ type }) => type === 'Internal')
            .map(({ label, link, slug, id }) => {
              if (isAbsolute) {
                return (
                  <a
                    key={id}
                    href={`https://skookum.com${link}`}
                    onClick={() => document.body.classList.remove('no-scroll')}
                    className={cx('nav-link', {
                      'nav-link--contact': slug === 'contact',
                    })}
                  >
                    {label}
                  </a>
                )
              }
              return (
                <Link
                  key={id}
                  to={link}
                  id={`${label.replace(' ', '_')}_btn`}
                  className={cx('nav-link', {
                    'nav-link--contact': slug === 'contact',
                  })}
                  onClick={() => document.body.classList.remove('no-scroll')}
                  activeClassName="nav-link--active"
                >
                  {label}
                </Link>
              )
            })}

          <a
            onClick={e => {
              e.preventDefault()
              this.setState({ isOpen: false })
              document.body.classList.remove('no-scroll')
            }}
            className="nav-close"
            title="Close"
          >
            <i className="icon-close icon--white" />
            <span className="screen-reader">Close Navigation</span>
          </a>
        </nav>
        <a
          onClick={e => {
            e.preventDefault()
            this.setState({ isOpen: true })
            document.body.classList.add('no-scroll')
          }}
          className="nav-open"
          title="Menu"
        >
          <i className="icon-menu icon--blue" />
          <span className="screen-reader">Navigation</span>
        </a>
      </div>
    )
  }
}

export default Navigation
