import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const CareerContact = ({
  content: {
    markdown,
    metadata: { link, linkTitle, className },
  },
}) => (
  <Section className={classNames('career-contact', className)} innerClassName="career-contact__wrapper">
    <section className="career-contact__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </section>
    <section className="career-contact__button">
      <a href={link} className="career-contact__email">
        {linkTitle}
      </a>
    </section>
  </Section>
)

export default CareerContact
