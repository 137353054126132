import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const BlogPostBody = ({ post }) => {
  if (!post) return null

  const { markdown } = post

  return (
    <article className="post">
      <main className="post" role="main">
        <Section className="post__section" innerClassName="post__inner" isCentered>
          <section className="post__content">
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </section>
        </Section>
      </main>
    </article>
  )
}

export default BlogPostBody
