import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const CaseStudyIntro = props => {
  const {
    content: { markdown, headline },
  } = props

  return (
    <Section className="case-intro" innerClassName="case-intro__wrapper" isCentered>
      <h2 className="case-intro__title">{headline}</h2>
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </Section>
  )
}

export default CaseStudyIntro
