import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const LocationIntro = props => {
  const {
    content: { markdown, images, headline, metadata },
    node: { name },
  } = props

  return (
    <Section
      className={classNames('location-intro', metadata ? metadata.className : '')}
      innerClassName="location-intro__wrapper"
    >
      <img className="location-intro__image" src={images[0].file.url} alt="skyline" />
      <div className="location-intro__content">
        <ReactMarkdown className="location-intro__markdown" escapeHtml={false} source={markdown.content} />
        <div className="location-intro__right">
          <iframe
            title={name}
            className="location-intro__map"
            frameBorder="0"
            style={{ border: 0 }}
            src={`https://www.google.com/maps/embed/v1/place?key=${'AIzaSyDW7_ySnIRh2N1zUUQ1CEADvCVD_sZtrMo'}&q=${headline}`}
            allowFullScreen
          />
        </div>
      </div>
    </Section>
  )
}

export default LocationIntro
