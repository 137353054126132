import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'
import downArrowImage from './cs2019-down-arrow.png'

const CaseStudyIntro2019 = props => {
  const {
    content: { data },
    metadata,
  } = props
  const [backgroundImage, featuredImage] = data[0].images
  return (
    <Section
      className={classNames('case-study-2019-intro', metadata && metadata.className)}
      innerClassName="case-study-2019-intro_wrapper"
    >
      <section className="case-study-2019-intro__background-image-wrapper">
        <img
          key={backgroundImage.id}
          className="case-study-2019-intro__background-image"
          src={backgroundImage.file.url}
          alt={backgroundImage.title}
        />
      </section>
      <div className="case-study-2019-intro__content">
        <section className="case-study-2019-intro__markdown">
          <ReactMarkdown escapeHtml={false} source={data[0].markdown.content} />
        </section>
        <section className="case-study-2019-intro__image">
          <img
            className="case-study-2019-intro__image"
            key={featuredImage.id}
            src={featuredImage.file.url}
            alt={featuredImage.title}
          />
        </section>
        <section className="case-study-2019-intro__quote">
          <ReactMarkdown escapeHtml={false} source={data[1].markdown.content} />
        </section>
      </div>
      <section className="case-study-2019-intro__square-wrapper">
        <img className="case-study-2019-intro__square" src={downArrowImage} alt="" />
      </section>
    </Section>
  )
}

export default CaseStudyIntro2019
