import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const GoodFit = props => {
  const {
    content: { markdown, images, metadata },
  } = props
  return (
    <Section
      className={classNames('good-fit', metadata ? metadata.className : '')}
      innerClassName="intro__wrapper"
      isCentered
    >
      <section className="good-fit__images">
        <div className="good-fit__left">
          {images.map(({ id, title, sizes }) => (
            <img
              key={id}
              className="good-fit__image"
              src={sizes.src}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              sizes="50vw"
              alt={title}
            />
          ))}
        </div>
      </section>
      <section className="good-fit__markdown">
        <ReactMarkdown escapeHtml={false} source={markdown.content} />
      </section>
      <hr />
    </Section>
  )
}

export default GoodFit
