import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const SkookumValues = ({
  content: {
    data,
    markdown: { content },
    metadata,
  },
}) => (
  <Section className={classNames('values', metadata ? metadata.className : '')} withPadding isCentered>
    <header className="values__header">
      <ReactMarkdown escapeHtml={false} source={content} />
    </header>
    <section className="values__items">
      {data.map(({ id, images, markdown }) => (
        <section className="values__item" key={id}>
          {images.map(({ file }) => (
            <i className="values__icon" key={id} style={{ backgroundImage: `url(${file.url})` }} />
          ))}
          <div className="values__item-content">
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </div>
        </section>
      ))}
    </section>
  </Section>
)

export default SkookumValues
