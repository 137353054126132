/* eslint-disable jsx-a11y/no-static-element-interactions, no-invalid-this, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */
import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

class ScrollQuote extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
    }
  }

  onArrowClick(arrowDirection) {
    const { content } = this.props
    const { length } = content.data
    const { currentIndex } = this.state

    if (arrowDirection === 'right' && currentIndex < length - 1) {
      this.setState({
        currentIndex: currentIndex + 1,
      })
    } else if (arrowDirection === 'right' && currentIndex === length - 1) {
      this.setState({
        currentIndex: 0,
      })
    }

    if (arrowDirection === 'left' && currentIndex > 0) {
      this.setState({
        currentIndex: currentIndex - 1,
      })
    } else if (arrowDirection === 'left' && currentIndex === 0) {
      this.setState({
        currentIndex: length - 1,
      })
    }
  }

  render() {
    const {
      content: { data },
    } = this.props
    const { currentIndex } = this.state

    return (
      <Section className="scroll-quote" innerClassName="scroll-quote__wrapper" isCentered>
        <a
          className={classNames('scroll-quote__left-arrow', {
            'scroll-quote__left-arrow--inactive': data.length === 1,
          })}
          onClick={() => {
            this.onArrowClick('left')
          }}
        >
          <ReactMarkdown escapeHtml={false} source="&lt" />
        </a>
        <div className="scroll-quote__content">
          <ReactMarkdown
            escapeHtml={false}
            source={data[currentIndex].markdown.content}
            key={data[currentIndex].id}
            className="scroll-quote__item"
          />
        </div>
        <a
          className={classNames('scroll-quote__right-arrow', {
            'scroll-quote__right-arrow--inactive': data.length === 1,
          })}
          onClick={() => {
            this.onArrowClick('right')
          }}
        >
          <ReactMarkdown escapeHtml={false} source="&gt" />
        </a>
      </Section>
    )
  }
}

export default ScrollQuote
