import React from 'react'
import ReactMarkdown from 'react-markdown'
import get from 'lodash/get'

const Footer = props => {
  const {
    content: { markdown, linkGroups },
  } = props
  const isAbsolute = get(props, 'node.metadata.subdomain')

  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__about">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </div>
        <div className="footer__link-lists">
          {linkGroups.map(({ links, metadata, id }) => (
            <ul key={id} className="footer__links">
              <li className="footer__label">{metadata.label}</li>
              {links.map(({ type, link, label, id: linkId }) => {
                const linkType =
                  isAbsolute && type !== 'External' ? (
                    <a href={`https://skookum.com${link}`} className="footer__link">
                      {label}
                    </a>
                  ) : (
                    <a href={link} className="footer__link">
                      {label}
                    </a>
                  )
                return <li key={linkId}>{linkType}</li>
              })}
            </ul>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default Footer
