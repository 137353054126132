/* eslint-disable jsx-a11y/no-static-element-interactions, no-invalid-this, jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid */
import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

class BlockOpenings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filterJobs: 'all',
    }
  }

  handleClick(filterJobs) {
    this.setState({
      filterJobs,
    })
  }

  render() {
    const {
      content: { markdown, images, metadata },
      jobs,
    } = this.props
    const { filterJobs } = this.state
    return (
      <Section className={classNames('openings', metadata ? metadata.className : '')} isCentered>
        <section className="openings__markdown">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </section>
        <section id="filter" className="openings__filters">
          <div className="openings__buttons">
            <a
              className={classNames('openings__buttons-all', {
                'openings__buttons-all--active': filterJobs === 'all',
              })}
              onClick={() => {
                this.handleClick('all')
              }}
            >
              ALL LOCATIONS
            </a>
            <a
              className={classNames('openings__buttons-clt', {
                'openings__buttons-clt--active': filterJobs === 'Charlotte',
              })}
              onClick={() => {
                this.handleClick('Charlotte')
              }}
            >
              CHARLOTTE, NC
            </a>
            <a
              className={classNames('openings__buttons-den', {
                'openings__buttons-den--active': filterJobs === 'Denver',
              })}
              onClick={() => {
                this.handleClick('Denver')
              }}
            >
              DENVER, CO
            </a>
          </div>
        </section>
        <section className="openings__items">
          {jobs
            // https://github.com/Skookum/skookum-gatsby/issues/205
            .filter(job => job.title !== '0')
            .map(
              job =>
                (filterJobs === 'all' || filterJobs === job.location.name) && (
                  <a
                    className={classNames('openings__item', {
                      'openings__item--den': job.location.name === 'Denver',
                    })}
                    key={job.id}
                    href={job.absolute_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="openings__item-top">
                      {images && images.length !== 0 && (
                        <img className="openings__item-icon" src={images[0].file.url} alt="location icon" />
                      )}
                      <p className="openings__item-location">{job.location.name}</p>
                    </div>
                    <h3 className="openings__item-title">{job.title}</h3>
                    <p className="openings__cta">Learn More</p>
                  </a>
                ),
            )}
        </section>
      </Section>
    )
  }
}

export default BlockOpenings
