import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { Section } from '../../../../index'

const BlockImageLinks = props => {
  const {
    content: { data, metadata },
  } = props

  return (
    <Section
      className={classNames('project', metadata ? metadata.className : '')}
      innerClassName="project__wrapper"
      isCentered
      withPadding
    >
      {data.map(({ id, name, subheadline, slug, featuredImage }) => (
        <div key={id} className="project__tile">
          {featuredImage.map(({ sizes, title }) => (
            <Link key={id} to={`/case-study/${slug}`}>
              <div className="project__image-wrapper">
                <img
                  className="project__image"
                  src={sizes.src}
                  srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
                  alt={title}
                  sizes="(max-width: 600px) 100vw, 50vw"
                />
              </div>
            </Link>
          ))}

          <Link to={`/case-study/${slug}`}>
            <h2 className="project__title">{name}</h2>
          </Link>
          <p className="project__subheadline">{subheadline}</p>
          <Link className="project__cta" to={`/case-study/${slug}`}>
            Learn More
          </Link>
        </div>
      ))}
    </Section>
  )
}

export default BlockImageLinks
