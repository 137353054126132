/* eslint-disable camelcase */

import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

const BlogCard = ({ image, metadata, slug, alternateStyle }) => {
  const { meta_title, meta_description } = metadata

  if (!image.sizes) return null
  const blogLink = `/blog/${slug}`

  return (
    <section className="blog-card">
      <div className="blog-card__container">
        <Link to={blogLink}>
          <img
            key={image.id}
            className="blog-card__image"
            src={image.sizes.src}
            srcSet={image.sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
            alt={image.title}
            sizes="600px"
          />
        </Link>
        <Link
          className={classNames('blog-card__title', { 'blog-card__title--alternate': alternateStyle })}
          to={blogLink}
        >
          {meta_title}
        </Link>
        <p className={classNames('blog-card__description', { 'blog-card__description--alternate': alternateStyle })}>
          {meta_description}
        </p>
        <Link className="blog-card__cta" to={blogLink}>
          Learn More
        </Link>
      </div>
    </section>
  )
}

export default BlogCard
