/* eslint-disable jsx-a11y/no-static-element-interactions, no-invalid-this, jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */
import React from 'react'
import cx from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

class LandingQuotes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
    }
  }

  onArrowClick(arrowDirection) {
    const { content } = this.props
    const { currentIndex } = this.state

    if (arrowDirection === 'right' && currentIndex < content.data.length - 1) {
      this.setState({
        currentIndex: currentIndex + 1,
      })
    } else if (arrowDirection === 'right' && currentIndex === content.data.length - 1) {
      this.setState({
        currentIndex: 0,
      })
    }

    if (arrowDirection === 'left' && currentIndex > 0) {
      this.setState({
        currentIndex: currentIndex - 1,
      })
    } else if (arrowDirection === 'left' && currentIndex === 0) {
      this.setState({
        currentIndex: content.data.length - 1,
      })
    }
  }

  render() {
    const {
      content: { data },
    } = this.props
    const { currentIndex } = this.state

    return (
      <Section className="landing-quotes" innerClassName="landing-quotes__wrapper" isCentered>
        <div className="landing-quotes__quotes">
          <a
            className={cx('landing-quotes__left-arrow', {
              'landing-quotes__left-arrow--inactive': data.length === 1,
            })}
            onClick={() => {
              this.onArrowClick('left')
            }}
          >
            <ReactMarkdown escapeHtml={false} source="&lt" />
          </a>
          <div className="landing-quotes__content">
            <img
              className="landing-quotes__ricon"
              alt={data[currentIndex].images[0].title}
              src={data[currentIndex].images[0].file.url}
              key={data[currentIndex].images[0].id}
            />
            <ReactMarkdown
              escapeHtml={false}
              source={data[currentIndex].markdown.content}
              key={data[currentIndex].id}
              className="landing-quotes__item"
            />
          </div>
          <a
            className={cx('landing-quotes__right-arrow', {
              'landing-quotes__right-arrow--inactive': data.length === 1,
            })}
            onClick={() => {
              this.onArrowClick('right')
            }}
          >
            <ReactMarkdown escapeHtml={false} source="&gt" />
          </a>
        </div>
        <a className="landing-success__button" href="#">
          REQUEST A FREE CONSULTATION
        </a>
      </Section>
    )
  }
}

export default LandingQuotes
