import React from 'react'
import { Section } from '../../../../index'

const LandingValues = props => {
  const {
    content: {
      metadata: { headline, subheadline, blockHeaders },
      data,
    },
  } = props

  return (
    <Section className="landing-values" innerClassName="landing-values__inner" withPadding isCentered>
      <h2 className="landing-values__title">{headline}</h2>
      <p className="landing-values__subtitle">{subheadline}</p>

      <div className="landing-values__items">
        {blockHeaders.map((title, index) => (
          <div key={title} className="landing-values__item">
            <h3 className="landing-values__item-title">{title}</h3>

            <ul className="landing-values__item-container">
              {data
                .filter(value => value.type === (index === 0 ? 'customer' : 'business'))
                .map(value => (
                  <li key={value.title} className="landing-values__item-value">
                    {value.title}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default LandingValues
