import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const CaseStudyResults2019 = props => {
  const {
    content: { markdown, images, metadata },
  } = props
  return (
    <Section
      className={classNames('case-study-2019-results', metadata && metadata.className)}
      innerClassName="case-study-2019-result__wrapper"
    >
      {images.slice(0, 1).map(({ id, title, file }) => (
        <div className="case-study-2019-results__background-image-wrapper">
          <img key={id} className="case-study-2019-results__background-image" src={file.url} alt={title} />
        </div>
      ))}
      {images.slice(1, 2).map(({ id, title, file }) => (
        <div className="case-study-2019-results__iPhone-image-wrapper">
          <img key={id} className="case-study-2019-results__iPhone-image" src={file.url} alt={title} />
        </div>
      ))}
      <div className="case-study-2019-results__stat-images-wrapper">
        {images.slice(2, 5).map(({ id, title, file }, index) => (
          <img
            key={id}
            className={classNames('case-study-2019-results__stat-images', {
              'case-study-2019-results__stat-images--first': index === 0,
              'case-study-2019-results__stat-images--second': index === 1,
              'case-study-2019-results__stat-images--third': index === 2,
            })}
            src={file.url}
            alt={title}
          />
        ))}
      </div>
      <section className="case-study-2019-results__content">
        <ReactMarkdown escapeHtml={false} source={markdown.content} />
      </section>
    </Section>
  )
}

export default CaseStudyResults2019
