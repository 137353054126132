import React from 'react'
import classNames from 'classnames'
import { Navigation } from '../../../../index'

const BlogHeader = props => {
  const {
    content: {
      linkGroups,
      metadata: { className },
    },
    location,
  } = props

  return (
    <header
      className={classNames('page-header', {
        [`page-header--${className}`]: className,
      })}
    >
      {linkGroups.map(group => (
        <Navigation key={group.id} links={group.links} location={location} />
      ))}
    </header>
  )
}

export default BlogHeader
