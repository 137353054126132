import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const PrivacyContent = ({ content: { markdown, metadata } }) => (
  <Section
    className={classNames('privacy', metadata ? metadata.className : '')}
    innerClassName="privacy__inner"
    isCentered
  >
    <section className="privacy__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </section>
  </Section>
)

export default PrivacyContent
