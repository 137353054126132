import React from 'react'
import ReactMarkdown from 'react-markdown'
import cx from 'classnames'
import { Section } from '../../../../index'

const LandingStats = props => {
  const {
    content: { data },
  } = props

  return (
    <Section className="landing-stats" innerClassName="landing-stats__wrapper" isCentered>
      <div className="landing-stats__items">
        {data.map(({ id, markdown }, index) => (
          <div
            key={id}
            className={cx('landing-stats__item', {
              'landing-stats__item--first': index === 0,
              'landing-stats__item--second': index === 1,
              'landing-stats__item--third': index === 2,
            })}
          >
            <ReactMarkdown escapeHtml={false} source={markdown.content} />
          </div>
        ))}
      </div>
    </Section>
  )
}

export default LandingStats
