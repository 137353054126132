import React from 'react'

import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const CaseStudyServices2019 = props => {
  const {
    content: { data },
  } = props

  return (
    <Section className="case-study-2019-services" innerClassName="case-study-2019-services__wrapper" isCentered>
      <div className="case-study-2019-services__content">
        {data.map(({ id, markdown }, index) => (
          <ReactMarkdown
            escapeHtml={false}
            source={markdown.content}
            key={id}
            className={classNames('case-study-2019-services__item', {
              'case-study-2019-services__item--first': index === 0,
              'case-study-2019-services__item--second': index === 1,
              'case-study-2019-services__item--third': index === 2,
            })}
          />
        ))}
      </div>
    </Section>
  )
}

export default CaseStudyServices2019
