import React from 'react'
import classNames from 'classnames'
import { BlogCard, Section } from '../../../../index'

const LatestBlogs = props => {
  const {
    content: { headline, metadata },
    blogs,
  } = props

  return (
    <Section className={classNames('latest-blogs', metadata ? metadata.className : '')} isCentered withPadding>
      <h2 className="latest-blogs__title">{headline}</h2>
      <div className="latest-blogs__grid">
        {blogs.map(blog => (
          <BlogCard className="latest-blogs__cta" key={blog.slug} {...blog} />
        ))}
      </div>
    </Section>
  )
}

export default LatestBlogs
