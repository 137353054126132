import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const CaseStudyResults = props => {
  const {
    content: { name, data },
  } = props

  return (
    <Section className="case-results" innerClassName="case-results__wrapper" isCentered>
      <h2 className="case-results__title">{name}</h2>
      <div className="case-results__items">
        {data.map(({ id, description }) => (
          <section key={id} className="case-results__item">
            <ReactMarkdown escapeHtml={false} source={description.description} />
          </section>
        ))}
      </div>
    </Section>
  )
}

export default CaseStudyResults
