/* eslint-disable max-len */

import React from 'react'
import Helmet from 'react-helmet'
import * as Components from 'skookum-components'
import get from 'lodash/get'

const DefaultTemplate = ({ location, pageContext }) => {
  const { node, blogs, paginate, post, jobs, events } = pageContext

  return (
    <React.Fragment>
      <Helmet
        title={get(node.metadata, 'meta_title')}
        encodeSpecialCharacters={false}
        meta={[
          {
            name: 'description',
            content: get(node.metadata, 'meta_description'),
          },
          { name: 'twitter:card', content: 'summary_large_image' },
          { name: 'twitter:site', content: '@skookum' },
          { name: 'twitter:title', content: get(node.metadata, 'meta_title') },
          {
            name: 'twitter:description',
            content: get(node.metadata, 'meta_description'),
          },
          {
            name: 'twitter:image',
            content: `https:${get(
              node,
              'featuredImage[0].sizes.src',
              '//images.ctfassets.net/hg6t9yitzgu8/6nPcjlteYo6uA2cQUY2QwG/69852433a623f09d1e69efc0477138c2/skookum-skookum-0044_2x__4_.jpg?w=2560&q=75&fm=jpg',
            )}`,
          },
          {
            name: 'twitter:image:alt',
            content: get(node, 'featuredImage[0].title', 'Skookum Image'),
          },
          { property: 'og:title', content: get(node.metadata, 'meta_title') },
          { property: 'og:site_name', content: 'Skookum' },
          { property: 'og:type', content: 'website' },
          {
            property: 'og:url',
            content: `https://skookum.com/${node.slug === 'index' ? '' : node.slug}`,
          },
          {
            property: 'og:description',
            content: get(node.metadata, 'meta_description'),
          },
          {
            property: 'og:image',
            content: get(
              node,
              'featuredImage[0].sizes.src',
              'https://images.ctfassets.net/hg6t9yitzgu8/6nPcjlteYo6uA2cQUY2QwG/69852433a623f09d1e69efc0477138c2/skookum-skookum-0044_2x__4_.jpg?w=2560&q=75&fm=jpg',
            ),
          },
        ]}
      />

      {(node.content || []).map(content => {
        if (content.component && content.component.componentName && Components[content.component.componentName]) {
          const ComponentType = Components[content.component.componentName]

          return (
            <ComponentType
              key={content.id}
              node={node}
              location={location}
              blogs={blogs}
              events={events}
              jobs={jobs}
              paginate={paginate}
              post={post}
              isLandingPage={get(node, 'metadata.isLandingPage', false)}
              content={{ ...content, metadata: content.metadata || {} }}
            />
          )
        }
        return null
      })}
    </React.Fragment>
  )
}

export default DefaultTemplate
