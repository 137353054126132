import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const ClientQuote = props => {
  const {
    content: { markdown, images, metadata, headline },
  } = props
  return (
    <Section
      className={classNames('client-quote', metadata && metadata.className)}
      innerClassName="client-quote__wrapper"
    >
      <section className="client-quote__title">
        <h1 className="client-quote__headline">{headline}</h1>
      </section>
      <section className="client-quote__content">
        <div className="client-quote__icon">
          {images.map(({ id, title, sizes }) => (
            <img
              key={id}
              className="client-quote__image"
              src={sizes.src}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              sizes="50vw"
              alt={title}
            />
          ))}
        </div>
        <div className="client-quote__markdown">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </div>
      </section>
    </Section>
  )
}

export default ClientQuote
