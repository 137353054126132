import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const WordyIntro = ({ content: { markdown } }) => (
  <Section className="wordy-intro" innerClassName="wordy-intro__wrapper" isCentered>
    <section className="wordy-intro__content">
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
      <hr />
    </section>
  </Section>
)

export default WordyIntro
