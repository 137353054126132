import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Navigation, Section } from '../../../../index'

const ServiceHeader = props => {
  const {
    content: {
      linkGroups,
      markdown,
      metadata: { className },
    },
    location,
    node: { featuredImage },
  } = props

  return (
    <header
      className={classNames('page-header', 'page-header--service', {
        [`page-header--${className}`]: className,
      })}
    >
      {linkGroups.map(group => (
        <Navigation key={group.id} links={group.links} location={location} />
      ))}

      <Section className="page-header__post-header" innerClassName="page-header__post-inner">
        <section className="page-header__service-content">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </section>
        <div className="page-header__wrapper page-header__wrapper--section-post">
          {featuredImage.map(({ id, title, sizes }) => (
            <img
              key={id}
              className={classNames('page-header__image', className)}
              src={`${sizes.src}&fl=progressive`}
              srcSet={sizes.srcSet.split('fm=jpg').join('fm=jpg&fl=progressive')}
              sizes="100vw"
              alt={title}
            />
          ))}
        </div>
      </Section>
    </header>
  )
}

export default ServiceHeader
