import React from 'react'
import classNames from 'classnames'
import { Section } from '../../../../index'

const LocationJobs = ({ jobs, content: { images, headline, metadata }, node: { name } }) => {
  const cJobs = jobs.slice()
  const dJobs = jobs.slice()
  let jSelect = jobs

  let i = 0
  let dNum = 0
  let cNum = 0

  for (i = 0; i < jobs.length; i += 1) {
    if (jobs[i].location.name === 'Charlotte' && cNum < 3) {
      cJobs[cNum] = jobs[i]
      cNum += 1
    } else if (jobs[i].location.name === 'Denver' && dNum < 3) {
      dJobs[dNum] = jobs[i]
      dNum += 1
    }
  }

  if (name === 'Charlotte') {
    jSelect = cJobs
  } else {
    jSelect = dJobs
  }

  return (
    <Section className={classNames('location-jobs', metadata ? metadata.className : '')} isCentered>
      {jobs.length !== 0 && (
        <section className="location-jobs__wrapper">
          <h1 className="location-jobs__headline">{headline}</h1>
          <div className="location-jobs__items">
            {jSelect.map(
              (job, index) =>
                index < 3 && (
                  <a
                    className={classNames('location-jobs__item', {
                      'location-jobs__item--den': job.location.name === 'Denver',
                    })}
                    key={job.id}
                    href={job.absolute_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="location-jobs__item-top">
                      {images && images.length !== 0 && (
                        <img className="location-jobs__item-icon" src={images[0].file.url} alt="location icon" />
                      )}
                      <p className="location-jobs__item-location">{job.location.name}</p>
                    </div>
                    <h3 className="location-jobs__item-title">{job.title}</h3>
                    <p className="location-jobs__cta">Learn More</p>
                  </a>
                ),
            )}
          </div>
          <a className="location-jobs__button" href="/careers" target="_blank" rel="noopener noreferrer">
            View All Openings
          </a>
        </section>
      )}
    </Section>
  )
}

export default LocationJobs
