import React from 'react'
import classNames from 'classnames'
import { Section } from '../../../../index'

class CaseStudyCollage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
    }
  }

  render() {
    const {
      content: { images },
    } = this.props
    const { selected } = this.state

    return (
      <Section className="case-collage" innerClassName="case-collage__wrapper" isCentered>
        <div className="case-collage__row">
          {images.map(({ id, title, sizes }) => (
            <div
              onMouseEnter={() => {
                if (images.length > 2) {
                  this.setState({ selected: id })
                }
              }}
              onMouseLeave={() => {
                if (images.length > 2) {
                  this.setState({ selected: null })
                }
              }}
              key={id}
              className={classNames('case-collage__container', {
                'is-selected': selected === id,
                'is-unselected': selected !== id && selected !== null,
              })}
            >
              <img className="case-collage__image" src={sizes.src} alt={title} />
            </div>
          ))}
        </div>
      </Section>
    )
  }
}

export default CaseStudyCollage
