import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const CaseStudyBackground = props => {
  const {
    content: { markdown, metadata },
  } = props

  return (
    <Section
      style={{ backgroundColor: metadata.hex }}
      className="case-background"
      innerClassName={classNames('case-background__wrapper', {
        'is-white': metadata.hex === '#ffffff',
      })}
      isCentered
    >
      <ReactMarkdown escapeHtml={false} source={markdown.content} />
    </Section>
  )
}

export default CaseStudyBackground
