import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const HPMvideo = props => {
  const {
    content: { images, markdown },
  } = props

  return (
    <Section className="hpm-video">
      <div className="hpm-video__container">
        <ReactMarkdown className="hpm-video__video" escapeHtml={false} source={markdown.content} />
        <img key={images[0].id} className="hpm-video__background" src={images[0].file.url} alt={images[0].title} />
      </div>
    </Section>
  )
}

export default HPMvideo
