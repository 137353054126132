import React from 'react'
import ReactMarkdown from 'react-markdown'
import classNames from 'classnames'
import { Section } from '../../../../index'

const LocationAccolades = props => {
  const {
    content: { markdown, images, metadata },
  } = props

  return (
    <Section
      className={classNames('location-accolades', metadata ? metadata.className : '')}
      innerClassName="location-accolades__wrapper"
    >
      <img className="location-accolades__background" src={images[0].file.url} alt="Accolades" />
      <div className="location-accolades__content">
        <ReactMarkdown className="location-accolades__markdown" escapeHtml={false} source={markdown.content} />
        <div className="location-accolades__right">
          {images.map(
            image =>
              images.indexOf(image) > 0 && (
                <div className="location-accolades__accolade">
                  <img
                    className={classNames('location-accolades__image', {
                      'location-accolades__image--left': images.indexOf(image) === 1,
                      'location-accolades__image--right': images.indexOf(image) === images.length - 1,
                    })}
                    key={image.id}
                    alt={image.title}
                    src={image.file.url}
                  />
                </div>
              ),
          )}
        </div>
      </div>
    </Section>
  )
}

export default LocationAccolades
