import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

class ContactForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')

    script.id = 'hsform'
    script.src = '//js.hsforms.net/forms/v2.js'
    script.async = true

    if (!document.querySelector('#hsform')) {
      document.body.appendChild(script)
    }

    this.initialize()
  }

  initialize() {
    const {
      content: { metadata },
      node: { slug },
    } = this.props
    if (window.hbspt) {
      const target = '#hubspot-contact-form'
      const inlineMessage = `
        ${metadata.link ? '' : '<div class="after-submit__image"></div>'}
        <div class="after-submit__content">
          <h3 class="after-submit__title">${metadata.title || 'Thanks!'}</h1>
          <p class="after-submit__text">${
            metadata.subtitle || metadata.link
              ? 'You can now download the eBook!'
              : 'One of our teammates will be in touch shortly.'
          }</p>
          ${
            metadata.link
              ? '<img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=50127&conversionId=354834&fmt=gif" />'
              : ''
          }
          ${
            metadata.link
              ? `<a class="after-submit__download after-submit__download--secondary" href="${metadata.link}" target="_blank" rel="noopener noreferrer">Download</a>`
              : ''
          }
        </div>
      `
      window.hbspt.forms.create({
        target,
        inlineMessage,
        css: '',
        cssClass: 'contact-form',
        submitButtonClass: 'button',
        portalId: '2886218',
        formId: metadata.formId,
        onFormSubmit: () => {
          if (window.fbq) {
            window.fbq('track', 'Lead')
          }
          if (metadata.link) {
            window.location = `/${slug}/thank-you`
          }
        },
      })
    } else {
      setTimeout(this.initialize.bind(this), 50)
    }
  }

  render() {
    const {
      content: { markdown, metadata },
    } = this.props

    if (!metadata.formId) {
      throw new Error("Please ensure that the Contentful ContactForm contain's a Hubspot formId in the metadata.")
    }

    return (
      <Section className="contact" innerClassName="contact__wrapper" isCentered>
        <div className="contact__markdown">
          <ReactMarkdown escapeHtml={false} source={markdown.content} />
        </div>
        <div className={classNames('contact__form', metadata.className)} id="hubspot-contact-form" />
      </Section>
    )
  }
}

export default ContactForm
