import React from 'react'
import { Section } from '../../../../index'

const NewStudyimg = props => {
  const {
    content: { images },
  } = props

  return (
    <Section className="new-study-img">
      <div className="new-study-img__background">
        <img
          key={images[0].id}
          className="new-study-img__image"
          sizes="100vw"
          src={images[0].file.url}
          alt={images[0].title}
        />
        {images.length > 1 && (
          <img key={images[1].id} className="new-study-img__image2" src={images[1].file.url} alt={images[1].title} />
        )}
      </div>
    </Section>
  )
}

export default NewStudyimg
