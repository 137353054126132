import React from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import { Section } from '../../../../index'

const CaseStudySolution2019 = props => {
  const {
    content: { markdown, headline, images, metadata },
  } = props
  return (
    <Section
      className={classNames('case-study-2019-solution', metadata && metadata.className)}
      innerClassName="case-study-2019-solution__wrapper"
      isCentered
    >
      <section className="case-study-2019-solution__content">
        <ReactMarkdown escapeHtml={false} source={markdown.content} />
      </section>
      {images.map(({ id, title, file }) => (
        <div className="case-study-2019-solution__image-wrapper">
          <hr className="case-study-2019-solution__image-background" />
          <img key={id} className="case-study-2019-solution__image" src={file.url} alt={title} />
          <div className="case-study-2019-solution__dash-line-wrapper">
            <hr className="case-study-2019-solution__dash-line" />
          </div>
          <h3 className="case-study-2019-solution__image-tag">{headline}</h3>
        </div>
      ))}
    </Section>
  )
}
export default CaseStudySolution2019
